import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import { isBasicColumn } from "components/Admin/utils";
import {
  getColumnOptionsLookupPropAndColumnName,
  getColumnValueFromRowData,
  readLookupFieldsForCellGroups
} from "lib/utils";
import { ApiRecordType, GenericCellColumn, GenericCellType } from "types/apiTypes";
import {
  ColumnRule,
  LookupEntry,
  Page,
  TabRecordIDSource,
  TableColumnType,
  TableFilterType,
  TableViewConfig
} from "types/baTypes";
import { GridViewColumn, RecordItem } from "types/common";
import { CellType, FILTER_OPERATOR, GENERIC_CELL_LAYOUTS, LookupTypes, ViewOption } from "utils/constants";
import { CompositePKey, ExtendedSchema } from "utils/schema";
import { getFormulaResultForColumn, getImageColumnLabel, isColumnDisplayInView } from "./columnUtils";
import { mapApiColRuleToFilters } from "./pageUtils";

export const withNestedChildren = (
  parentItem: RecordItem,
  allItems: RecordItem,
  key: string,
  nestedItemKey = "children",
  finalChildrenObj: RecordItem[] = [],
  sortKey = "sort_order"
) => {
  const finalChildList = [...finalChildrenObj];
  const finalObject = { ...parentItem, id: parentItem.id, [nestedItemKey]: [] };
  const relationKey = parentItem[key] !== "null" ? parentItem[key] : undefined;
  finalObject[nestedItemKey] =
    relationKey && allItems[relationKey]?.length
      ? sortBy(
          allItems[parentItem[key]].map((child: RecordItem) => {
            return withNestedChildren(child, allItems, key, nestedItemKey, finalChildList, sortKey);
          }),
          sortKey
        )
      : [];
  return finalObject;
};

export const flatMapArrayWithChildrens = (allItems: RecordItem[], nestedItemKey = "children"): RecordItem[] => {
  return allItems.flatMap((item: RecordItem) => {
    const children = item[nestedItemKey] || [];
    return [item, ...flatMapArrayWithChildrens(children)];
  });
};

export const parseFormulaToValidExpression = (
  colOptions: GridViewColumn[],
  formula: string,
  parseToLabel = false
): string => {
  let retry = false;
  colOptions.forEach((colOpt) => {
    if (formula.includes(colOpt.id)) {
      retry = true;
      if (colOpt.formula) {
        formula = formula.replaceAll(`{${colOpt.id}}`, parseToLabel ? colOpt.header : `(${colOpt.formula})`);
      } else {
        formula = formula.replaceAll(`{${colOpt.id}}`, parseToLabel ? colOpt.name : `{${colOpt.name}}`);
      }
    }
  });

  if (retry) {
    return parseFormulaToValidExpression(colOptions, formula);
  }
  return formula;
};

export const findFeaturedOrFirstFromJoinFiles = (joinFilesRecord: RecordItem) => {
  if (!joinFilesRecord || !joinFilesRecord?.length) return null;

  const featuredFile = joinFilesRecord.find((file: RecordItem) => file.is_featured);
  if (featuredFile) {
    return featuredFile.file_id;
  }
  return joinFilesRecord[0].file_id;
};

export const findFeaturedOrFirstFromImageColumnForRecordType = (files?: RecordItem[]) => {
  if (!files?.length) return null;

  const featuredFile = files.find((file: RecordItem) => file.is_featured);
  if (featuredFile) {
    return featuredFile;
  }
  return files[0];
};

/**
 *
 * @param columns : should be filtered by viewType instead of all columns
 */
export const getRecordForViewType = ({
  record,
  viewType,
  columnOptions,
  finalColumns,
  extendedSchema,
  recordTypesData,
  includeHiddenColumns,
  basePageTableName
}: {
  record: Record<string, any>;
  viewType: ViewOption;
  columnOptions: TableColumnType[];
  finalColumns?: TableColumnType[];
  extendedSchema?: ExtendedSchema;
  recordTypesData?: ApiRecordType[];
  includeHiddenColumns?: boolean;
  basePageTableName?: string;
}) => {
  if (!finalColumns?.length) {
    return {};
  }

  const finalRecord: any = {};
  finalColumns.forEach((column) => {
    if (isColumnDisplayInView(column, viewType) || (column?.views?.[viewType]?.isHidden && includeHiddenColumns)) {
      if (column.isLookup) {
        const { lookupPath } = column;

        if (!isEmpty(lookupPath)) {
          const finalName =
            lookupPath["0"].lookupColumnLabel ||
            lookupPath["0"].lookupForeignKey ||
            lookupPath["0"].lookupTableName ||
            "";
          if (column.type === CellType.GENERIC_CELL) {
            finalRecord[finalName] = getGenericCellValuesFromRecord({
              column,
              recordData: record,
              columns: columnOptions,
              view: viewType
            });
          } else if (column.type === CellType.ADDRESS) {
            finalRecord[finalName] = {
              id: record.address1 ? record?.id : record[finalName]?.id,
              address1: record.address1 || record[finalName]?.address1,
              address2: record.address2 || record[finalName]?.address2,
              city: record.city || record[finalName]?.city,
              state: record.state || record[finalName]?.state,
              zip: record.zip || record[finalName]?.zip
            };
          } else {
            if (!finalName.startsWith("image_")) {
              // Pick "0" or first lookupLevel tableName as property name
              finalRecord[finalName] = readLookupFieldsForCellGroups(
                column,
                record,
                extendedSchema,
                recordTypesData,
                basePageTableName
              );
            }
          }
        }
      } else if (column.isFormula) {
        const formulaResult = getFormulaResultForColumn({
          formulaColumn: column,
          allColumns: columnOptions,
          record,
          isForm: false
        });
        if (Number.isFinite(formulaResult)) {
          if (column.name) {
            finalRecord[column.name] = formulaResult;
          } else {
            finalRecord[column.id] = formulaResult;
          }
        }
      } else if (column.isRollup && !column.name) {
        if (column.rollupConfig?.sourceColId) {
          const sourceColumn = columnOptions.find((col) => col.id === column.rollupConfig?.sourceColId);
          if (sourceColumn) {
            const sourceColPropName =
              sourceColumn?.lookupPath?.["0"]?.lookupColumnLabel ||
              sourceColumn?.lookupPath?.["0"]?.lookupForeignKey ||
              sourceColumn?.lookupPath?.["0"]?.lookupTableName ||
              "";
            const rollupCol = column.rollupConfig?.sourceColColumn;
            finalRecord[column.id] = record[sourceColPropName][rollupCol];
          }
        }
      } else if (column.type === CellType.BUTTON && !column.name) {
        finalRecord[column.id] = undefined;
      } else if (column.type === CellType.GENERIC_CELL && !column.name) {
        finalRecord[column.id] = getGenericCellValuesFromRecord({
          column,
          recordData: record,
          columns: finalColumns,
          view: viewType,
          recordTypesData
        });
      } else if (column.name) {
        if (column.type === CellType.ADDRESS) {
          finalRecord[column.name] = {
            address1: record.address1,
            address2: record.address2,
            city: record.city,
            state: record.state,
            zip: record.zip
          };
        } else if (column.type === CellType.GENERIC_CELL) {
          finalRecord[column.name] = getGenericCellValuesFromRecord({
            column,
            recordData: record,
            columns: columnOptions,
            view: viewType,
            recordTypesData
          });
        } else {
          finalRecord[column.name] = record[column.name];
        }
      }
    }
  });

  return finalRecord;
};

/**
 *
 * @param columns : should be filtered by viewType instead of all columns
 */
export const getColumnHeaderTypeForRecordKey = (columns?: TableColumnType[], formViewConfig?: TableViewConfig) => {
  if (!columns?.length) {
    return {};
  }
  const columnHeaderMap: Record<
    string,
    { type: CellType; header: string; column: TableColumnType; columnRules: RecordItem }
  > = {};
  columns.forEach((column: TableColumnType) => {
    if (column.isLookup) {
      const { lookupPath } = column;
      if (lookupPath?.["0"]) {
        const finalName =
          lookupPath["0"].lookupColumnLabel ||
          lookupPath["0"].lookupForeignKey ||
          lookupPath["0"].lookupTableName ||
          "";
        // Links to lookupLevel 0 tableName
        columnHeaderMap[finalName] = {
          header: column.header,
          type: column.type,
          columnRules: { required: !!formViewConfig?.requiredColumns?.includes(column.id) },
          column
        };
      }
    }
    if (
      (column.isRollup ||
        column.type === CellType.BUTTON ||
        column.isFormula ||
        column.type === CellType.GENERIC_CELL) &&
      !column.name
    ) {
      columnHeaderMap[column.id] = {
        header: column.header,
        type: column.type,
        column,
        columnRules: { required: !!formViewConfig?.requiredColumns?.includes(column.id) }
      };
    } else if (column.name) {
      columnHeaderMap[column.name] = {
        header: column.header,
        type: column.type,
        column,
        columnRules: { required: !!formViewConfig?.requiredColumns?.includes(column.id) }
      };
    }
  });

  return columnHeaderMap;
};

// Returns the lookupcolumn name to read from formatted record
export const getLastLookupColumnName = (column: TableColumnType) => {
  const { lookupPath } = column;
  if (column.type === CellType.PEOPLE) {
    return "full_name";
  }
  if (lookupPath) {
    const allLookupLevels: string[] = Object.keys(lookupPath);
    if (allLookupLevels?.length) {
      const lastLevel: string = allLookupLevels.pop() || "";
      const lastLookupLevel = lookupPath[lastLevel];
      return lastLookupLevel.lookupDisplayColumn || lastLookupLevel.lookupColumns?.["0"] || "";
    }
  }
  return "";
};

// Returns true if base table is a join table with tags
export const isColumnTagsJoinTable = (column: TableColumnType, extendedSchema?: ExtendedSchema) => {
  if (!column.isLookup || !column.lookupPath) return false;
  const { lookupPath } = column;
  const level1TableProps = extendedSchema?.[lookupPath?.[0]?.lookupTableName];
  if (!level1TableProps) return false;
  // Check if level 1 table has tags in composite key
  if (level1TableProps?.compositePk?.find((cKey) => cKey.table === "tags")) {
    return true;
  }
  return false;
};

export const isColumnFileTag = (column: TableColumnType) => {
  if (!column.isLookup || !column.lookupPath) return false;
  const { lookupPath } = column;
  const lookupTables = Object.keys(lookupPath)
    .map((lookupLevel) => lookupPath?.[lookupLevel]?.lookupTableName)
    .filter(Boolean);
  // If there is only one level and that is files_tags table return true
  if (lookupTables?.length === 1 && lookupTables?.[0] === "files_tags") {
    return true;
  }
  // Lookup path includes `files_tags` table
  if (!lookupTables.includes("files_tags")) {
    return false;
  }

  // Last lookup level is `tags` table with `name` lookup column
  const lastLookupLevel = lookupPath[lookupTables.length - 1];
  if (
    !lastLookupLevel ||
    (lastLookupLevel?.lookupTableName !== "tags" && lastLookupLevel?.lookupTableName !== "files_tags") ||
    !lastLookupLevel?.lookupColumns?.includes("name")
  ) {
    return false;
  }
  return true;
};

// If lookupTableName is tags or is a join table with tags table returns true
export const isTagsRelatedTable = (column: TableColumnType, extendedSchema?: ExtendedSchema) => {
  if (!column.isLookup || !column.lookupPath || !extendedSchema) return false;
  const { lookupPath } = column;
  const lookupTables = Object.keys(lookupPath)
    .map((lookupLevel) => lookupPath?.[lookupLevel]?.lookupTableName)
    .filter(Boolean);
  if (lookupTables?.includes("tags")) return true;
  let hasTagsRelation = false;
  for (let index = 0; index < lookupTables.length; index++) {
    const tableName = lookupTables[index];
    const tableProps = extendedSchema?.[tableName];
    if (tableProps?.compositePk?.find((cKey) => cKey.table === "tags")) {
      hasTagsRelation = true;
      break;
    }
  }
  return hasTagsRelation;
};

export const isColumnNestedFileTag = (column: TableColumnType) => {
  if (!column.isLookup || !column.lookupPath) return false;
  const { lookupPath } = column;
  const lookupTables = Object.keys(lookupPath)
    .map((lookupLevel) => lookupPath?.[lookupLevel]?.lookupTableName)
    .filter(Boolean);
  const filesTagIndex = lookupTables?.indexOf("files_tags");
  if (filesTagIndex > 0) return true;
  return false;
};

// Works for join tables with files
export const getFileIdFromRecordBasedOnLoookupPath = (record: RecordItem, column: TableColumnType) => {
  if (!column.isLookup || !column.lookupPath) return null;
  const { lookupPath } = column;
  const firstLevel = lookupPath["0"];
  return record?.[firstLevel?.lookupColumnLabel || firstLevel?.lookupForeignKey || ""];
};

// Utility to know if the page has a file table
export const hasAFileTable = (tableName?: string, extendedSchema?: ExtendedSchema) => {
  if (!tableName || !extendedSchema) return false;
  if (tableName === "files") return true;
  if (extendedSchema[tableName]?.compositePk?.length) {
    return !!extendedSchema[tableName]?.compositePk?.find((key) => key.table === "files");
  }
  return false;
};

// Utility to know if the page has a note table
export const hasANoteTable = (tableName?: string, extendedSchema?: ExtendedSchema) => {
  if (!tableName || !extendedSchema) return false;
  if (tableName === "notes") return true;
  if (extendedSchema[tableName]?.compositePk?.length) {
    return !!extendedSchema[tableName]?.compositePk?.find((key) => key.table === "notes");
  }
  return false;
};

// Utility to get the parent record id
export const getParentRecord = ({
  tableName,
  parentRecordPage,
  parentRecordId,
  extendedSchema
}: {
  tableName: string;
  parentRecordPage?: Page;
  parentRecordId?: string;
  extendedSchema: ExtendedSchema;
}) => {
  if (!parentRecordPage || !parentRecordId) return;

  const compositeKey = extendedSchema[tableName].compositePk;
  const parentRecordCompositeKey = compositeKey?.find((key) => key.table === parentRecordPage.table_name);

  if (!parentRecordCompositeKey) {
    return;
  }

  return {
    name: parentRecordCompositeKey.attributeId,
    record: {
      id: parseInt(parentRecordId)
    },
    key: compositeKey,
    attribute: parentRecordCompositeKey.attributeId
  };
};

// Returns the record property value set as record id source for a tab
// Only returns the value directly
export const getPropertyFromRecordSource = ({
  columns,
  record,
  recordIdSource,
  columnFiltersFormRecordSource
}: {
  columns: TableColumnType[];
  record: RecordItem;
  recordIdSource: TabRecordIDSource;
  columnFiltersFormRecordSource?: RecordItem; // This comes from form specifically and is not the full record
}) => {
  if (!recordIdSource?.columnId || (isEmpty(record) && isEmpty(columnFiltersFormRecordSource))) {
    return null;
  }
  const recordColumn = columns.find((column) => column.id === recordIdSource.columnId);
  if (!recordColumn) {
    return null;
  }

  if (recordIdSource.columnName) {
    return record[recordIdSource.columnName];
  }
  let finalRecord = columnFiltersFormRecordSource ? columnFiltersFormRecordSource : { ...record };
  const isColFiltersRecordSource = !isEmpty(columnFiltersFormRecordSource);
  let finalValue = null;
  if (recordIdSource?.columnRecordLookupPath) {
    if (isColFiltersRecordSource) {
      // In this case we only check lookup level 0 and pick the column
      const colLookupPath = recordIdSource.columnRecordLookupPath?.["0"];
      if (colLookupPath?.lookupColumns?.length) {
        finalValue = finalRecord[colLookupPath.lookupColumns[0]];
      }
    } else {
      Object.keys(recordIdSource.columnRecordLookupPath).forEach((lookupLevel) => {
        const colLookupPath = recordColumn.lookupPath?.[lookupLevel];
        const recordlookupPath = recordIdSource.columnRecordLookupPath?.[lookupLevel];
        if (colLookupPath && recordlookupPath) {
          if (recordlookupPath.lookupTableName) {
            if (Array.isArray(finalRecord) && finalRecord.length) {
              finalRecord =
                finalRecord[0][
                  colLookupPath.lookupColumnLabel ||
                    colLookupPath.lookupForeignKey ||
                    colLookupPath.lookupTableName ||
                    ""
                ];
            } else {
              finalRecord =
                finalRecord[
                  colLookupPath.lookupColumnLabel ||
                    colLookupPath.lookupForeignKey ||
                    colLookupPath.lookupTableName ||
                    ""
                ];
            }
          }
          if (recordlookupPath.lookupColumns?.length && !isEmpty(finalRecord)) {
            const finalColumn = recordlookupPath.lookupColumns[0];
            const isColForeignKey = !!colLookupPath.lookupDBType?.[finalColumn]?.isFKey;
            if (Array.isArray(finalRecord) && finalRecord.length) {
              finalValue = finalRecord[0][recordlookupPath.lookupColumns[0] || ""];
            } else {
              finalValue = finalRecord[recordlookupPath.lookupColumns[0] || ""];
            }
            if (isColForeignKey && finalValue?.id) {
              finalValue = finalValue.id;
            }
          }
        }
      });
    }
  }
  return finalValue;
};

// Return id for property at level 0 of lookupPath
export const getFirstLevelRecordIdForColumn = ({
  column,
  recordData
}: {
  column: TableColumnType;
  recordData: RecordItem;
}) => {
  if (column.name) return recordData?.[column.name];
  if (!column.isLookup || !column.lookupPath || !recordData) return null;

  const firstLevel = column.lookupPath["0"];
  return (
    recordData?.[firstLevel?.lookupColumnLabel || firstLevel?.lookupForeignKey || firstLevel?.lookupTableName || ""]
      ?.id || null
  );
};

export const arraysEqual = (a?: string[], b?: string[]) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  const sortedA = [...a].sort();
  const sortedB = [...b].sort();

  return sortedA.every((value, index) => value === sortedB[index]);
};

export const getRecordDataKeyForColumn = (column: TableColumnType) => {
  if (!column.isLookup || !column.lookupPath) return column.name;

  const firstLevel = column.lookupPath["0"];
  return firstLevel?.lookupColumnLabel || firstLevel?.lookupForeignKey || firstLevel?.lookupTableName;
};

// Returns string value for the config
const getGenericValue = ({
  record,
  lookupDetails,
  genericConfigValue
}: {
  record: RecordItem;
  lookupDetails: RecordItem;
  genericConfigValue: RecordItem;
}) => {
  if (!genericConfigValue) return "";

  const { isFKLookupCol } = genericConfigValue;
  if (isFKLookupCol) {
    const { foreignLookupColumns } = lookupDetails;
    if (!foreignLookupColumns?.length) return "";
    const finalColumn = foreignLookupColumns.find((fkCol: LookupEntry) =>
      fkCol.lookupColumns?.includes(genericConfigValue.columnName)
    );
    if (finalColumn) {
      const { lookupForeignKey } = finalColumn;
      if (!lookupForeignKey) return "";
      return record?.[lookupForeignKey]?.[genericConfigValue.columnName] || "";
    }
    return "";
  }
  if (lookupDetails?.lookupForeignKey && genericConfigValue?.lookupLevel !== "0") {
    const finalPropName = lookupDetails?.lookupForeignKey || lookupDetails?.lookupTableName || "";
    if (Array.isArray(record)) {
      return Array.isArray(record?.[0]?.[finalPropName])
        ? record?.[0]?.[finalPropName]?.[0]?.[genericConfigValue.columnName] || ""
        : record?.[0]?.[finalPropName]?.[genericConfigValue.columnName] || "";
    }
    return record?.[finalPropName]?.[genericConfigValue.columnName] || "";
  }
  return record?.[genericConfigValue.columnName] || "";
};

// Returns record data in the Generic cell format
// { title?: string; subTitle?: string; display?: string; displayType?: "color" | "icon" | "file" };
// Always returns an array of values
export const getGenericCellValuesFromRecord = ({
  column,
  recordData,
  columns,
  view,
  isInOptions = false,
  recordTypesData,
  extendedSchema,
  inGlobalSearch = false
}: {
  column: TableColumnType;
  columns?: TableColumnType[];
  recordData: RecordItem;
  view?: ViewOption;
  isInOptions?: boolean;
  recordTypesData?: ApiRecordType[];
  extendedSchema?: ExtendedSchema;
  inGlobalSearch?: boolean;
}) => {
  const genericConfig = isInOptions
    ? column?.cellConfig?.genericConfigForColumnOptions
    : column?.cellConfig?.genericConfig;

  if (!genericConfig) return null;

  const lookupPath = isInOptions ? column.columnOptionsLookUp : column.lookupPath;

  // if column is of basic type
  if (inGlobalSearch || genericConfig.isBasicColumn) {
    const finalValue: {
      title?: string;
      subTitle?: string;
      display?: string;
      displayType?: "color" | "icon" | "file";
    } & RecordItem = {};

    finalValue.layout = genericConfig.layout;

    if (genericConfig.layout === GENERIC_CELL_LAYOUTS.COMMA_SEPARATED) {
      if (genericConfig.columns) {
        genericConfig.columns.forEach((col) => {
          finalValue[col.columnName] = recordData[col.columnName];
        });
      }
    } else if (genericConfig.layout === GENERIC_CELL_LAYOUTS.COMBINATION_CELL) {
      if (genericConfig.columns && columns) {
        genericConfig.columns.forEach((col) => {
          if (col.columnId) {
            const columnConfig = columns?.find((column) => column.id === col.columnId);
            if (columnConfig) {
              const value = getColumnValueFromRowData({
                row: recordData,
                col: columnConfig,
                colOptions: columns,
                view,
                extendedSchema,
                recordTypesData
              });

              if (value) {
                finalValue[col.columnId] = {
                  column: columnConfig,
                  value
                };
              }
            }
          }
        });
      }
    } else {
      if (genericConfig.title) {
        finalValue.title = recordData[genericConfig.title.columnName];
      }
      if (genericConfig.subTitle) {
        if (Array.isArray(genericConfig.subTitle)) {
          finalValue.subTitle = genericConfig.subTitle
            .map((field) =>
              recordData[field.columnName] && Array.isArray(recordData[field.columnName]) && inGlobalSearch
                ? recordData[field.columnName]?.join(", ")
                : recordData[field.columnName]
            )
            .filter(Boolean)
            .join(", ");
        } else {
          finalValue.subTitle = recordData[genericConfig.subTitle.columnName];
        }
      }

      if (genericConfig.icon) {
        finalValue.icon = genericConfig.icon;
      }

      if (genericConfig.display) {
        if (inGlobalSearch) {
          let displayValue = recordData[genericConfig.display.columnName];
          if (typeof displayValue !== "string") {
            if (Array.isArray(displayValue)) {
              displayValue =
                genericConfig.display.displayType === "file"
                  ? displayValue[0]?.path
                  : displayValue[0]?.[genericConfig.display.displayType];
            } else {
              displayValue =
                genericConfig.display.displayType === "file"
                  ? displayValue?.path
                  : displayValue[0]?.[genericConfig.display.displayType];
            }
          }
          finalValue.display = displayValue;
        } else {
          finalValue.display =
            recordData[genericConfig.display.columnName] || recordData[genericConfig.display.displayType];
        }
        finalValue.displayType = genericConfig.display.displayType;
      }
    }

    return [finalValue];
  }

  const finalRecord: Array<{
    id?: string;
    title?: string;
    subTitle?: string;
    display?: string;
    displayType?: "color" | "icon" | "file";
  }> = [];
  const firstLookupDetails = lookupPath?.["0"];
  const columnRecord =
    firstLookupDetails?.lookupTableName && !isInOptions
      ? recordData[
          firstLookupDetails.lookupColumnLabel ||
            firstLookupDetails.lookupForeignKey ||
            firstLookupDetails.lookupTableName
        ]
      : recordData;

  if (!columnRecord) return null;

  let finalGenericConfig: RecordItem = {};

  if (
    genericConfig?.layout === GENERIC_CELL_LAYOUTS.COMMA_SEPARATED ||
    genericConfig?.layout === GENERIC_CELL_LAYOUTS.COMBINATION_CELL
  ) {
    genericConfig?.columns?.forEach((col: GenericCellColumn) => {
      finalGenericConfig[col.columnName] = col;
    });
  } else {
    finalGenericConfig = genericConfig;
  }

  if (Array.isArray(columnRecord)) {
    columnRecord.forEach((record: RecordItem) => {
      const finalGenericRecord: RecordItem = {
        layout: genericConfig?.layout,
        id: record?.id
      };
      Object.keys(finalGenericConfig).forEach((key: string) => {
        const genericConfigValue: RecordItem = finalGenericConfig[key as keyof GenericCellType];
        if (genericConfigValue?.lookupLevel) {
          const lookupDetails = lookupPath?.[genericConfigValue.lookupLevel];
          let lookupLevelRecord = columnRecord;
          if (parseInt(genericConfigValue?.lookupLevel, 10) > 1) {
            // Send nested record till one level before the lookup level
            let currentLevel = 1;
            while (currentLevel < parseInt(genericConfigValue?.lookupLevel, 10)) {
              const propName =
                lookupPath?.[currentLevel]?.lookupColumnLabel ||
                lookupPath?.[currentLevel]?.lookupForeignKey ||
                lookupPath?.[currentLevel]?.lookupTableName ||
                "";
              if (Array.isArray(lookupLevelRecord)) {
                lookupLevelRecord = lookupLevelRecord["0"]?.[propName || ""];
              } else {
                lookupLevelRecord = lookupLevelRecord[propName || ""];
              }
              currentLevel++;
            }
          }
          if (lookupDetails) {
            const configRecord = getGenericValue({ record: lookupLevelRecord, lookupDetails, genericConfigValue });
            if (configRecord) {
              finalGenericRecord[key] = configRecord;
              if (key === "display") {
                finalGenericRecord["displayType"] = genericConfigValue.displayType;
              }
            }
          }
        }
      });
      if (!isEmpty(finalGenericRecord)) {
        finalRecord.push(finalGenericRecord);
      }
    });
  } else {
    const finalGenericRecord: RecordItem = {
      layout: genericConfig?.layout,
      id: columnRecord?.id
    };
    Object.keys(finalGenericConfig).forEach((key: string) => {
      const genericConfigValue: RecordItem = finalGenericConfig[key as keyof GenericCellType];
      if (genericConfigValue?.lookupLevel) {
        const lookupDetails = lookupPath?.[genericConfigValue.lookupLevel];
        let lookupLevelRecord = columnRecord;
        if (parseInt(genericConfigValue?.lookupLevel, 10) > 1) {
          // Send nested record till one level before the lookup level
          let currentLevel = 1;
          while (currentLevel < parseInt(genericConfigValue?.lookupLevel, 10)) {
            const propName =
              lookupPath?.[currentLevel]?.lookupColumnLabel ||
              lookupPath?.[currentLevel]?.lookupForeignKey ||
              lookupPath?.[currentLevel]?.lookupTableName ||
              "";
            if (Array.isArray(lookupLevelRecord)) {
              lookupLevelRecord = lookupLevelRecord["0"]?.[propName || ""];
            } else {
              lookupLevelRecord = lookupLevelRecord[propName || ""];
            }
            currentLevel++;
          }
        }
        if (lookupDetails) {
          const configRecord = getGenericValue({ record: lookupLevelRecord, lookupDetails, genericConfigValue });
          if (configRecord) {
            finalGenericRecord[key] = configRecord;
            if (key === "display") {
              finalGenericRecord["displayType"] = genericConfigValue.displayType;
            }
          }
        }
      }
    });
    if (!isEmpty(finalGenericRecord)) {
      finalRecord.push(finalGenericRecord);
    }
  }
  return finalRecord;
};

export const getIsProductPlaceholder = (column: TableColumnType, row: RecordItem): boolean => {
  if (column?.lookupPath?.[0]?.lookupTableName === "products") {
    const level0 = column.lookupPath[0];

    const key = level0.lookupColumnLabel || level0.lookupForeignKey || level0.lookupTableName;

    if (key) {
      return !!row?.[key]?.is_placeholder;
    }
  }

  return !!row.is_placeholder;
};

export const getThumbnailPath = (featuredImageColumn: TableColumnType, row: RecordItem) => {
  if (!featuredImageColumn?.lookupPath) return row?.thumbnail_path;

  const level0 = featuredImageColumn.lookupPath[0];
  if (!level0?.lookupTableName) return null;
  const prop = row[level0.lookupColumnLabel || level0.lookupForeignKey || level0.lookupTableName];
  if (!prop) return null;
  const finalProps = Array.isArray(prop) ? prop[0] : prop;
  return finalProps?.thumbnail_path;
};

/**
 *
 * @param columns -> list of all pageColumns
 * @returns array of column Ids which are used in basic Generic cell config type
 */
export const getBasicGenericCellDataColumnIds = (columns: TableColumnType[]) => {
  return columns
    .filter((col) => isBasicColumn(col) && col.type === CellType.GENERIC_CELL)
    .reduce((acc: string[], col) => {
      if (!col.cellConfig?.genericConfig) return acc;
      if (col.cellConfig?.genericConfig?.title) {
        const titleCol = columns.find((column) => column.name === col.cellConfig?.genericConfig?.title?.columnName);
        if (titleCol) {
          acc.push(titleCol.id);
        }
      }
      if (col.cellConfig.genericConfig?.subTitle) {
        if (Array.isArray(col.cellConfig.genericConfig.subTitle)) {
          col.cellConfig.genericConfig.subTitle.forEach((configCol) => {
            const subTitleCol = columns.find((column) => column.name === configCol?.columnName);
            if (subTitleCol) {
              acc.push(subTitleCol.id);
            }
          });
        } else {
          const subtitleColumnName = col?.cellConfig?.genericConfig?.subTitle?.columnName;
          const subTitleCol = columns.find((column) => column.name === subtitleColumnName);
          if (subTitleCol) {
            acc.push(subTitleCol.id);
          }
        }
      }

      if (col.cellConfig?.genericConfig?.display) {
        const displayCol = columns.find((column) => column.name === col.cellConfig?.genericConfig?.display?.columnName);
        if (displayCol) {
          acc.push(displayCol.id);
        }
      }

      if (col.cellConfig?.genericConfig.columns) {
        col.cellConfig.genericConfig.columns.forEach((genericCellCol) => {
          const foundCol = columns.find((column) =>
            genericCellCol.columnId ? column.id === genericCellCol.columnId : column.name === genericCellCol.columnName
          );
          if (foundCol) {
            acc.push(foundCol.id);
          }
        });
      }
      return acc;
    }, []);
};

export const getImageColumnForRecordType = (
  matchingRecType: ApiRecordType,
  col: TableColumnType,
  extendedSchema?: ExtendedSchema
) => {
  const updatedRecLookup: RecordItem = {};
  const joinUpdatedRecLookup: RecordItem = {};
  const hasRecordBasicCols = Object.keys(matchingRecType?.config || {})?.length;
  const isLvl2RecordTypeForeignKey = col.lookupPath?.["0"]?.isLvl2RecordTypeForeignKey;
  const incrementLookupLevel = isLvl2RecordTypeForeignKey ? 1 : 0;
  Object.keys(matchingRecType?.image_column?.lookup_path || {}).forEach((lookupLevel) => {
    const updatedLookupLevel = parseInt(lookupLevel) + 1 + incrementLookupLevel;
    let isFeaturedFilesLevel = {};
    // Condition to handle featured image file foreign key on record type
    if (
      matchingRecType?.image_column?.lookup_path?.[lookupLevel]?.lookupForeignKey &&
      matchingRecType?.image_column?.lookup_path?.[lookupLevel]?.lookupTableName === "files"
    ) {
      isFeaturedFilesLevel = {
        lookupColumns: ["id", "path", "width", "height", "metadata->mimetype", "file_type", "thumbnail_path"]
      };
    }
    // Remove col labels as this is not level 0
    updatedRecLookup[updatedLookupLevel] = {
      ...matchingRecType?.image_column?.lookup_path?.[lookupLevel],
      ...isFeaturedFilesLevel,
      lookupColumnLabel: ""
    };

    joinUpdatedRecLookup[updatedLookupLevel + 1] = {
      ...matchingRecType?.image_column?.lookup_path?.[lookupLevel],
      ...isFeaturedFilesLevel,
      lookupColumnLabel: ""
    };
  });

  let finalLookupPath: Record<string, LookupEntry> = {};

  if (col.lookupPath?.["0"]?.lookupForeignKey) {
    if (!isLvl2RecordTypeForeignKey) {
      finalLookupPath = {
        0: {
          ...col.lookupPath?.["0"],
          lookupColumnLabel: getImageColumnLabel(col),
          lookupColumns: hasRecordBasicCols
            ? [...Object.keys(matchingRecType?.config || {}), ...(col.lookupPath?.["0"]?.lookupColumns || [])]
            : [...(col.lookupPath?.["0"]?.lookupColumns || [])]
        } as LookupEntry,
        ...updatedRecLookup
      };
    } else {
      finalLookupPath = {
        0: {
          ...col.lookupPath?.["0"],
          lookupColumnLabel: getImageColumnLabel(col)
        },
        1: {
          ...col.lookupPath?.["1"],
          lookupColumns: hasRecordBasicCols
            ? [...Object.keys(matchingRecType?.config || {}), ...(col.lookupPath?.["0"]?.lookupColumns || [])]
            : [...(col.lookupPath?.["0"]?.lookupColumns || [])]
        } as LookupEntry,
        ...updatedRecLookup
      };
    }
  } else if (
    col.lookupPath?.["0"]?.lookupType === LookupTypes.JOIN &&
    extendedSchema?.[col.lookupPath?.["0"]?.lookupTableName]
  ) {
    const compositePK = extendedSchema?.[col.lookupPath?.["0"]?.lookupTableName]?.compositePk;

    // We need another level to the foreign key
    const fkLevel = compositePK?.find((cKey) => cKey.table === matchingRecType?.tablename);
    if (fkLevel) {
      finalLookupPath = {
        0: {
          ...col.lookupPath?.["0"],
          lookupColumnLabel: getImageColumnLabel(col)
        } as LookupEntry,
        1: {
          lookupForeignKey: fkLevel.attributeId,
          lookupType: LookupTypes.FOREIGN,
          lookupColumns: hasRecordBasicCols
            ? [...Object.keys(matchingRecType?.config || {}), ...(col.lookupPath?.["1"]?.lookupColumns || [])]
            : [...(col.lookupPath?.["1"]?.lookupColumns || [])],
          lookupTableName: fkLevel?.table
        },
        ...joinUpdatedRecLookup
      };
    }
  }

  let finalLookupFilters = matchingRecType?.image_column?.lookup_filters
    ? mapApiColRuleToFilters(matchingRecType?.image_column?.lookup_filters)
    : col.lookupFilters;
  if (finalLookupFilters) {
    finalLookupFilters = finalLookupFilters.map((filter) => {
      const finalFilterLookupPath: Record<string, LookupEntry> = {};
      for (const lookupLevel in finalLookupPath) {
        // only picking first level lookup filters
        if (
          finalLookupPath?.[lookupLevel]?.lookupTableName ===
          finalLookupFilters?.["0"]?.filterLookupPath?.["0"]?.lookupTableName
        ) {
          Object.keys(finalLookupFilters?.["0"]?.filterLookupPath || {}).forEach((filterLookupLevel, index) => {
            finalFilterLookupPath[`${parseInt(lookupLevel) + index}`] = {
              ...finalLookupFilters?.["0"]?.filterLookupPath?.[filterLookupLevel],
              lookupColumnLabel:
                filterLookupLevel === "0"
                  ? finalLookupPath?.[lookupLevel]?.lookupColumnLabel
                  : finalLookupFilters?.["0"]?.filterLookupPath?.[filterLookupLevel]?.lookupColumnLabel
            } as LookupEntry;
          });
          break;
        } else {
          finalFilterLookupPath[lookupLevel] = finalLookupPath?.[lookupLevel];
        }
      }
      return {
        ...filter,
        filterLookupPath: finalFilterLookupPath
      };
    });
  }
  const imgCol: TableColumnType = {
    ...col,
    header: "Image",
    id: `image_${col.id}`,
    lookupPath: finalLookupPath,
    columnOptionsLookUp: undefined,
    type: CellType.FILE,
    lookupFilters: finalLookupFilters
  };
  return imgCol;
};

// Updates filters column having matchingRecrordType with correct column
export const getUpdatedFiltersForRecordType = ({
  recordTypesData,
  filters,
  extendedSchema
}: {
  recordTypesData?: ApiRecordType[];
  filters: TableFilterType[];
  extendedSchema?: ExtendedSchema;
}) => {
  // update filters columns having matchingRecrordType with correct column
  const updatedFilters = filters.map((filter) => {
    const updatedFilter = { ...filter };
    const filterColumn = (updatedFilter as TableFilterType)?.column;
    if (filterColumn) {
      const matchingRecordType = recordTypesData?.find((recordType) => recordType.type === filterColumn.type);
      if (
        Object.keys(matchingRecordType?.image_column?.lookup_path || {})?.length &&
        matchingRecordType?.page_id &&
        !matchingRecordType?.lookup_column?.id
      ) {
        const imgCol = getImageColumnForRecordType(matchingRecordType, { ...filterColumn }, extendedSchema);
        updatedFilter.column = imgCol;
        updatedFilter.isFilterColumnImageRecordType = true;
      }
    }
    return updatedFilter;
  });

  return updatedFilters;
};

export const generateFinalDataColumns = ({
  columns,
  view,
  filters,
  config,
  additionalColMatch,
  isAdmin = false,
  recordTypesData,
  tableName,
  extendedSchema,
  pickAllColumns,
  conditionalFormatting
}: {
  columns?: TableColumnType[] | null;
  view?: ViewOption;
  filters?: TableFilterType[];
  config?: TableViewConfig;
  additionalColMatch?: (col: TableColumnType) => boolean;
  isAdmin?: boolean;
  recordTypesData?: ApiRecordType[];
  tableName?: string;
  extendedSchema?: ExtendedSchema;
  pickAllColumns?: boolean; // Used to bypass all column and view checks and returns all columns sent
  conditionalFormatting?: ColumnRule[] | null;
}) => {
  if (!columns?.length) {
    return [];
  }

  const basicGenericCellColumns = getBasicGenericCellDataColumnIds(columns);
  const titleColIds =
    config?.additionalConfig?.multiTitle
      ?.filter((item) => item.type === "column" && item.value)
      .map((item) => item.value) || [];
  const subtitleColIds =
    config?.additionalConfig?.multiSubtitle
      ?.filter((item) => item.type === "column" && item.value)
      .map((item) => item.value) || [];
  const featuredImgCol = columns?.find((col) => col.views?.[view || ""]?.isFeaturedImage);
  const groupByCol = columns?.find((col) => col.views?.[ViewOption.GRID]?.isGroupBy);
  const groupBySecondCol = columns?.find((col) => col.views?.[ViewOption.GRID]?.isGroupBySecond);
  const featuredImageColId = featuredImgCol?.id;
  const sortColId = config?.additionalConfig?.sortDragAndDrop?.columnToUpdate;
  const startDateColId = config?.additionalConfig?.startDateColumn;
  const endDateColId = config?.additionalConfig?.endDateColumn;
  const durationColId = config?.additionalConfig?.durationColumn;
  const titleColId = config?.additionalConfig?.titleColumn;
  const subtitleColId = config?.additionalConfig?.subtitleColumn;
  const descriptionColId = config?.additionalConfig?.descriptionColumn;
  const peopleColId = config?.additionalConfig?.peopleColumn;
  const colorColId = config?.additionalConfig?.colorColumn;
  const coordinateCol = columns?.find((col) => col.id === config?.additionalConfig?.coordinateColumn);
  const allRecTypes = recordTypesData?.map((recType) => recType.type) || [];
  const recTypeColumns: TableColumnType[] = []; // Add all columns that have matching record_types
  const basicCoordinateColumns: TableColumnType[] = []; // Add the latitude and longitude column for basic coordinate cell type
  const rowPinningColumn = columns?.find((col) => col.id === config?.additionalConfig?.rowPinningColumn);
  const recordHeaderColumnIds = columns
    ?.filter((col) => col.type === CellType.BUTTON && col.cellConfig?.buttonProps?.showInRecordDetailHeader)
    .map((col) => col.id);

  const conditionalFormattingColumnIds = conditionalFormatting?.map((rule) => rule.columnRules?.[0]?.columnId) || [];

  const mapMarkerTitleColumnId = config?.additionalConfig?.mapMarkerTitleColumn;

  const allCols =
    columns?.filter((col) => {
      const isInView = view ? col.views?.[view]?.id : true;
      const isInFilters = filters?.find(
        (filter) =>
          filter.column?.id === col.id ||
          (filter.filterOperator === FILTER_OPERATOR.OR &&
            filter.filterGroup?.find((filter) => filter?.column?.id === col.id))
      );
      const isPartOfGenericCell = basicGenericCellColumns.includes(col.id);

      const matchWithAdditionalColMath = additionalColMatch?.(col) || false;

      // These columns are required by config
      const isTitleColumn = titleColIds.includes(col.id) || titleColId === col.id;
      const isSubtitleColumn = subtitleColIds.includes(col.id) || subtitleColId === col.id;
      const isFeaturedColumn = featuredImageColId === col.id;
      const isGroupByColumn = groupByCol?.id === col.id || groupBySecondCol?.id === col.id;
      const isSortDnDColumn = sortColId === col.id;
      const isStartDateColumn = startDateColId === col.id;
      const isEndDateColumn = endDateColId === col.id;
      const isDurationColumn = durationColId === col.id;
      const isCoordinateColumn = coordinateCol?.id === col.id;
      const isRowPinningColumn = rowPinningColumn?.id === col.id;
      const isDescriptionColumn = descriptionColId === col.id;
      const isRecordDetailHeaderColumn = recordHeaderColumnIds.includes(col.id);
      const isMapMarkerTitleColumn = mapMarkerTitleColumnId === col.id;
      const isColFormattingRuleColumn = conditionalFormattingColumnIds.includes(col.id);
      const isPeopleColumn = peopleColId === col.id;
      const isColorColumn = colorColId === col.id;

      const isRequiredByConfig =
        isTitleColumn ||
        isSubtitleColumn ||
        isFeaturedColumn ||
        isGroupByColumn ||
        isSortDnDColumn ||
        isStartDateColumn ||
        isEndDateColumn ||
        isDurationColumn ||
        isCoordinateColumn ||
        isRowPinningColumn ||
        isDescriptionColumn ||
        isRecordDetailHeaderColumn ||
        isMapMarkerTitleColumn ||
        isColFormattingRuleColumn ||
        isPeopleColumn ||
        isColorColumn;

      // Is in view or is required by config or is in filters or is part of generic cell
      const isNeeded =
        isInView || isRequiredByConfig || isInFilters || isPartOfGenericCell || matchWithAdditionalColMath;

      const isRollupWithoutName = !!col.isRollup && !col.name;

      const isButtonAction = isAdmin
        ? col.type === CellType.BUTTON && col.cellConfig?.buttonHandlerName
        : col.type === CellType.BUTTON && !col.cellConfig?.isLink;

      const isBasicGenericCell = isBasicColumn(col) && col.type === CellType.GENERIC_CELL;

      // for basic coordinate column type we will add coulmn using the cellConfig values for latitude and longitude columns
      const isBasicCoordinateColumn = isBasicColumn(col) && col.type === CellType.COORDINATE;
      const isHidden = view ? !!col.views?.[view]?.isHidden : false;

      const isValidCol =
        (isNeeded &&
          !isRollupWithoutName &&
          !isButtonAction &&
          !isBasicGenericCell &&
          !isBasicCoordinateColumn &&
          !col.isDeleted) ||
        isHidden ||
        pickAllColumns;
      if (isValidCol && allRecTypes.includes(col.type) && Object.keys(col.lookupPath || {}).length <= 2) {
        const matchingRecType = recordTypesData?.find((recType) => recType.type === col.type);
        // If an image column is present we need to add this to the final columns fetched
        // Only added for new columns
        if (
          matchingRecType?.page_id &&
          matchingRecType?.image_column?.lookup_path &&
          Object.keys(col.lookupPath || {})?.length &&
          tableName !== matchingRecType?.tablename
        ) {
          const imgCol = getImageColumnForRecordType(matchingRecType, col, extendedSchema);
          recTypeColumns.push(imgCol);
        }
      }

      if (isBasicCoordinateColumn) {
        const latitudeColumnName = col.cellConfig?.latitude_coordinate_column;
        const longitudeColumnName = col.cellConfig?.longitude_coordinate_column;
        // add the basic columns
        if (latitudeColumnName) {
          basicCoordinateColumns.push({
            ...col,
            id: `${col.id}_latitude`,
            name: latitudeColumnName,
            header: `${col.header} Latitude`
          });
        }

        if (longitudeColumnName) {
          basicCoordinateColumns.push({
            ...col,
            id: `${col.id}_longitude`,
            name: longitudeColumnName,
            header: `${col.header} Longitude`
          });
        }
      }

      return isValidCol;
    }) || [];

  return [...allCols, ...recTypeColumns, ...basicCoordinateColumns];
};

export const getCompositeKeysFromCols = (columns?: TableColumnType[], extendedSchema?: ExtendedSchema) => {
  if (!extendedSchema || !columns || !columns?.length) {
    return;
  }

  const lookupCols = columns.filter((col) => col.isLookup);
  const compositeKeys: { [key: string]: CompositePKey[] } = {};
  lookupCols.forEach((col) => {
    if (!col.lookupPath) return;

    Object.keys(col.lookupPath).forEach((lookupLevel) => {
      const lookupTableName = col.lookupPath?.[lookupLevel]?.lookupTableName;

      if (lookupTableName) {
        compositeKeys[lookupTableName] = extendedSchema[lookupTableName]?.compositePk || [];
      }
    });
  });

  return compositeKeys;
};

export const getDataIdsFromIndex = (data: RecordItem[], index: number) => {
  let fromIndex = index - 10 > 0 ? index - 10 : 0;
  return Array.from(
    {
      length: data.length <= 20 ? data.length : fromIndex + 20 > data?.length ? data?.length - fromIndex : 20
    },
    () => data[fromIndex++]?.id
  );
};

// Returns column label including column id and lookup relation
export const getColumnLookupLabel = (column: TableColumnType, lookupPath: LookupEntry) => {
  if (!column.isLookup || !lookupPath) return "";

  const firstLevel = lookupPath;
  return `${firstLevel?.lookupForeignKey || firstLevel?.lookupTableName || ""}_${column.id.slice(
    0,
    3
  )}_${column.id.slice(-3)}`;
};

export const getAggregateFunctionData = ({
  column,
  recordData
}: {
  column: TableColumnType;
  recordData: RecordItem;
}) => {
  if (!column?.cellConfig?.aggregateFunction?.isCombinedAggregate) return null;
  const aggregateFunctionConfig = column.cellConfig.aggregateFunction;
  if (!aggregateFunctionConfig?.function) return null;

  if (column?.cellConfig?.aggregateFunction?.isCombinedAggregate) {
    return Array.isArray(recordData)
      ? recordData?.[0]?.[aggregateFunctionConfig.function]
      : recordData?.[aggregateFunctionConfig.function];
  }

  if (!column.isLookup) {
    return recordData?.[aggregateFunctionConfig.function];
  }

  const { finalPropName } = getColumnOptionsLookupPropAndColumnName(column, true, true);
  if (!finalPropName) return null;

  if (Array.isArray(finalPropName)) {
    const aggrLookupLevel = aggregateFunctionConfig?.lookupLevel;
    if (aggrLookupLevel === "0") {
      return recordData?.[finalPropName[0]]?.[aggregateFunctionConfig.function];
    } else {
      const valLvl1 = recordData?.[finalPropName[0]];
      if (valLvl1 && Array.isArray(valLvl1)) {
        return valLvl1[0]?.[finalPropName[1]]?.[aggregateFunctionConfig.function];
      }
      return valLvl1?.[finalPropName[1]]?.[aggregateFunctionConfig.function];
    }
  } else {
    const val = recordData?.[finalPropName];
    if (Array.isArray(val)) {
      return val[0]?.[aggregateFunctionConfig.function];
    }
    return val?.[aggregateFunctionConfig.function];
  }
};

export const sanitiseDataToString = (text: string) => {
  // Remove HTML tags
  const stringWithoutTags = text.replace(/<[^>]*>/g, "");

  // Remove new line characters
  const stringWithoutNewLines = stringWithoutTags.replace(/\n/g, "");

  // Remove quotes characters
  const stringWithoutQuotes = stringWithoutNewLines.replace(/['"]/g, "");

  return stringWithoutQuotes;
};

/**
 * This method returns a record data object with empty values for all columns passed
 * No id column is returned
 * @param columns -> list of all page columns
 */
export const getEmptyRecordDataFromColumns = (columns: TableColumnType[]) => {
  const emptyRecordData: RecordItem = {};
  columns.forEach((col) => {
    if (col.name !== "id") {
      if (col.isLookup) {
        const { lookupPath } = col;
        const colLabel =
          lookupPath?.["0"]?.lookupColumnLabel ||
          lookupPath?.["0"]?.lookupForeignKey ||
          lookupPath?.["0"]?.lookupTableName ||
          "";
        emptyRecordData[colLabel] = null;
      } else {
        emptyRecordData[col.name] = col.type === CellType.BOOLEAN ? false : null;
      }
    }
  });

  return emptyRecordData;
};
