import { NestedViewStack } from "context/NestedViewContext";
import {
  ApiAlgoliaSearchTable,
  ApiColumnCondition,
  ApiColumnRule,
  ApiColumnSection,
  ApiContactPhoneNumber,
  ApiMenuAction,
  ApiMenuItem,
  ApiPage,
  ApiPageAction,
  ApiPageColumn,
  ApiPageViewColumn,
  ApiPageViews,
  ApiPageViewTabs,
  ApiUserHistory,
  ApiUserPreference,
  ApiView,
  ApiViewColumnSection,
  User
} from "types/apiTypes";
import {
  AlgoliaSearchTable,
  ColumnConditionalRule,
  ContactPhoneNumber,
  MenuAction,
  MenuItem,
  Page,
  PageAction,
  TableColumnType,
  TableFilterType,
  TablePageViewColumn,
  TablePageViewTab,
  TableViewConfig,
  UserHistory,
  UserPreference,
  ViewSection
} from "types/baTypes";
import { NestedViewRecordProps, PAGE_TYPES, RecordItem } from "types/common";
import { FILTER_OPERATOR, LookupTypes, NOTES_LINKS_TABLE, ViewOption } from "./constants";
import { getPropertyFromRecordSource } from "./dataUtils";
import { ExtendedSchema } from "./schema";
import { getNotesLinkForeignKey } from "./notesUtils";
import { formatPhoneNumber } from "./format";

// Checks if currentTablePage is a tab in the nested view stack
// Returns the query params of the path to the record if true
export const getRecordInNestedView = (nestedViewStack?: NestedViewStack, currentTablePage?: Page) => {
  if (!nestedViewStack?.length || !currentTablePage?.table_name || nestedViewStack?.length > 1) {
    return {
      isNestedView: false
    };
  }
  const currentNestedView = nestedViewStack[nestedViewStack.length - 1];
  const detailTabs = currentNestedView.pageData?.views?.find((view) => view.viewType === ViewOption.DETAIL_MAIN)?.tabs;
  if (!currentNestedView || !detailTabs?.length) {
    return {
      isNestedView: false
    };
  }
  const currentTab = detailTabs.find((tab) => tab.page?.id === currentTablePage.id);
  if (!currentTab) {
    return {
      isNestedView: false
    };
  }
  // One level nesting
  if (nestedViewStack.length === 1) {
    return {
      isNestedView: true,
      queryParams: [
        {
          tabId: currentTab.id,
          tabPath: currentTab.page?.path || "",
          nestedRecordId: currentNestedView.recordId
        }
      ]
    };
  }
};

export const getPageViewRoute = (page?: Page, isMobile?: boolean, isActivityTable?: boolean) => {
  const defaultView =
    page?.page_type === PAGE_TYPES.MULTI_PAGES
      ? ViewOption.MULTI_PAGES
      : page?.default_view || (isActivityTable ? ViewOption.ACTIVITY : ViewOption.GRID);
  const mobileDefaultView = page?.mobile_default_view || ViewOption.CARD;

  const viewsAvailable = [...(page?.views || []), ViewOption.NOTE];
  const hasMobileView = mobileDefaultView ? viewsAvailable.some((view) => view === mobileDefaultView) : false;

  if (isMobile && hasMobileView) {
    return mobileDefaultView;
  }

  return defaultView;
};

export const getNestedPageUrl = (
  nestedViewRecordProps: NestedViewRecordProps,
  recordId: string,
  pathParts: string[]
) => {
  let finalURL = "";

  nestedViewRecordProps?.queryParams?.forEach((param) => {
    if (nestedViewRecordProps?.isNestedView) {
      finalURL += `/${param.nestedRecordId}`; // Record id is of the parent
      finalURL += `${param.tabPath}`;
    } else {
      finalURL += `${param.tabPath}`;
      finalURL += `/${param.nestedRecordId}`; // Record id is of the parent
    }
  });
  let finalPath = "";
  if (pathParts.includes("r") && pathParts.length > 3) {
    finalPath = nestedViewRecordProps?.isNestedView
      ? `/${pathParts.join("/")}${finalURL}/${recordId}`
      : `/${pathParts.join("/")}/${recordId}${finalURL}`;
  }
  return finalPath;
};

export const mapApiColumnRuleToPageColumnRule = (apiColRules: ApiColumnRule[]) => {
  return apiColRules.map((apiColRule) => ({
    id: apiColRule.id,
    columnRules: apiColRule.columnRules?.map((rule: ApiColumnCondition) => ({
      id: rule.id,
      columnId: rule.pageColumn?.columns_id || rule?.viewColumn?.columns_id,
      pageColumnId: rule.pageColumn?.id || rule.ui_page_column_id,
      viewColumnId: rule.viewColumn?.id,
      operator: rule.operator,
      value: rule.value || rule.option_value,
      field: rule.field,
      lookupColumnNameField: rule.lookup_column_name_field,
      filterGroup: rule.filter_group,
      includeNull: rule.include_null
    })) as ColumnConditionalRule[],
    ruleConfig: apiColRule.rule_config
  }));
};

export const mapApiColRuleToFilters = (apiFilters: ApiColumnRule) => {
  return apiFilters.columnRules
    ?.map((rule) => {
      const hiddenRoleIds =
        rule?.colConditionRoles
          ?.map((role) => (role.role_id && role.is_hidden ? role.role_id : null))
          .filter(Boolean) || [];
      let filterGroup = undefined;
      if (rule?.operator === FILTER_OPERATOR.OR) {
        // Find other group conditions
        filterGroup = apiFilters?.columnRules
          ?.filter((subrule) => !!subrule?.group_condition_id && subrule?.group_condition_id === rule.id)
          .map((groupRule) => ({
            id: groupRule.id,
            columnId: groupRule.pageColumn?.columns_id || groupRule?.viewColumn?.columns_id,
            pageColumnId: groupRule.pageColumn?.id,
            viewColumnId: groupRule.viewColumn?.id,
            filterOperator: groupRule.operator,
            filterValue: groupRule.value || rule.option_value,
            filterField: groupRule.field,
            filterLookupPath: groupRule.filter_lookup_path,
            originalRuleItem: apiFilters,
            recordIdSource: groupRule.record_id_source,
            includeNull: groupRule.include_null,
            isParentSynced: !!groupRule.is_parent_synced,
            isUserVisible: !!rule.is_user_visible,
            filterDbType: groupRule.filter_db_type,
            groupConditionId: groupRule.group_condition_id
          }));
      }
      if (!!rule?.group_condition_id) {
        return null;
      }
      return {
        id: rule.id,
        columnId: rule.pageColumn?.columns_id || rule?.viewColumn?.columns_id,
        pageColumnId: rule.pageColumn?.id,
        viewColumnId: rule.viewColumn?.id,
        filterOperator: rule.operator,
        filterValue: rule.value || rule.option_value,
        filterField: rule.field,
        filterLookupPath: rule.filter_lookup_path,
        originalRuleItem: apiFilters,
        recordIdSource: rule.record_id_source,
        includeNull: rule.include_null,
        isParentSynced: !!rule.is_parent_synced,
        isUserVisible: !!rule.is_user_visible,
        filterDbType: rule.filter_db_type,
        filterGroup,
        hiddenRoleIds
      };
    })
    .filter(Boolean) as TableFilterType[];
};

export const mapApiColumnSectionsToSections = (
  apiSections?: Array<ApiColumnSection | ApiViewColumnSection>
): ViewSection[] => {
  if (!apiSections?.length) return [];
  return apiSections.map((apiSection) => {
    const accessRoleIds = apiSection?.sectionRoles?.map((role) => role.role_id || "") || [];
    return {
      id: apiSection.id,
      title: apiSection.title,
      description: apiSection?.description,
      webhookUrl: apiSection?.webhook_url,
      config: apiSection?.config,
      sort: apiSection?.sort,
      isClose: apiSection?.is_close,
      accessRoleIds,
      isAdminOnly: !!accessRoleIds?.find((roleId) => `${roleId}` === "40"),
      viewId: apiSection?.view_id,
      pageViewId: apiSection?.page_view_id,
      userId: apiSection?.for_user_id,
      filters: apiSection?.filter ? mapApiColRuleToFilters(apiSection.filter) : undefined,
      pageViewColumns: apiSection?.pageViewColumns?.map((pvCol) => ({
        columnId: pvCol.ui_pages_views_columns_id?.columns_id
      })),
      viewColumns: (apiSection as ApiViewColumnSection)?.viewColumns?.map((vCol) => ({
        columnId: vCol.ui_views_columns_id?.columns_id
      })),
      type: apiSection?.type,
      pageId: apiSection?.page_id,
      conditionalViewRules: apiSection?.conditionalViewRules
        ? mapApiColumnRuleToPageColumnRule([apiSection?.conditionalViewRules])?.[0]
        : undefined,
      isCustomMenuSection: apiSection?.is_custom_menu_section
    };
  });
};

export const mapApiViewAndViewColSectionsToSections = (
  apiColSections?: Array<{ id: string; section: ApiColumnSection | ApiViewColumnSection }>
) => {
  if (!apiColSections?.length) return [];

  return mapApiColumnSectionsToSections(apiColSections.map((apiColSection) => apiColSection.section));
};

export const mapPageColumnsToType = (
  apiColumns: ApiPageColumn[],
  pageViews: ApiPageViews[],
  pageId: string,
  isViewColumn?: boolean
): TableColumnType[] => {
  const convertedCols = apiColumns.map((pageCol) => {
    const accessRoleIds = pageCol.column?.columnRoles?.map((role) => role.role_id) || [];
    return {
      id: pageCol.column.id,
      name: pageCol.column.name,
      nickname: pageCol.column.nickname,
      description: pageCol.column.description,
      header: pageCol.column.header,
      workspaceId: pageCol.column.workspace_id,
      createdAt: pageCol.column.created_at,
      pageColumnId: isViewColumn ? undefined : pageCol.id,
      viewColumnId: isViewColumn ? (pageCol as RecordItem).viewColumnId : undefined, // This is set when mapping custom view columns
      pageId: pageCol.page_id,
      isChild: !!pageCol.is_child,
      hasFilter: !!pageCol.column.has_filter,
      filterSortOrder: pageCol.column.filter_sort_order,
      hasBulkEdit: !!pageCol.column.has_bulk_edit,
      cellConfig: pageCol.column.cell_config,
      isDeleted: !isViewColumn ? !!pageCol.is_deleted : !!pageCol.column.is_deleted, // If it's a custom view column there is no page column
      type: pageCol.column.type,
      isStatic: !!pageCol.column.is_static,
      isLookup: !!pageCol.column.is_lookup,
      isFormula: !!pageCol.column.is_formula,
      lookupPath: pageCol.column.lookup_path,
      isRollupSource: !!pageCol.column.is_rollup_source,
      columnOptionsLookUp: pageCol.column.column_options_lookup,
      columnOptionSortOrder: pageCol.column.column_options_sort,
      columnGroupBySortOrder: pageCol.column.column_options_group_sort,
      isMultiple: !!pageCol.column.is_multiple,
      isEditable: !!pageCol.column.is_editable,
      enableInlineEditing: !!pageCol.column.is_inline_editable,
      isSelect: !!pageCol.column.is_select,
      dbType: pageCol.column.db_type,
      createPageId: pageCol.column.create_page_id,
      createPageDefaultValues: pageCol.column.create_page_defaults,
      defaultValues: pageCol.column.default_values,
      formula: pageCol.column.formula,
      rollupConfig: pageCol.column.rollup_config,
      configErrors: pageCol.column.config_errors,
      isRollup: !!pageCol.column.rollup_config?.sourceColId,
      writeRollupConfig: pageCol.column.write_rollup_config,
      syncedParentColId: pageCol.column.parent_col_id,
      isAdminColumn: !!accessRoleIds?.find((roleId) => `${roleId}` === "40"),
      columnFilters: pageCol.columnFilters ? mapApiColRuleToFilters(pageCol.columnFilters) : undefined,
      lookupFilters: pageCol.lookupFilters ? mapApiColRuleToFilters(pageCol.lookupFilters) : undefined,
      addFilters: pageCol.addFilters ? mapApiColRuleToFilters(pageCol.addFilters) : undefined,
      columnFiltersId: pageCol.columnFilters?.id,
      lookupFiltersId: pageCol.lookupFilters?.id,
      algoliaSearchTables: pageCol.algoliaSearchTables?.map((entry) => ({
        id: entry.id,
        algoliaSearchTableId: entry.algolia_search_table_id
      })),
      conditionalViewRules: pageCol.column.conditionalViewRules
        ? mapApiColumnRuleToPageColumnRule([pageCol.column.conditionalViewRules])?.[0]
        : undefined,
      sortOrder:
        isViewColumn && (pageCol as RecordItem).viewColumnSortOrder
          ? (pageCol as RecordItem).viewColumnSortOrder
          : pageCol.column.ui_pages_views_columns?.reduce((acc: any, viewCol) => {
              if (viewCol?.view?.pages_id === pageId && !viewCol?.view?.is_custom) {
                acc[viewCol?.view_type || ""] = viewCol.sort;
              }
              return acc;
            }, {}),
      views:
        pageCol.column.ui_pages_views_columns?.reduce((acc: any, viewCol) => {
          // View link should be to the right page
          // TODO: Resolve this with the right structure + query
          if (viewCol?.view?.pages_id === pageId && !viewCol?.view?.is_custom && !viewCol?.view?.views_id?.is_deleted) {
            const view = pageViews?.find(
              (pageView) => pageView.view?.id === viewCol.view?.id && pageView.pages_id === pageId
            );
            acc[viewCol?.view_type || ""] = {
              id: viewCol.id,
              viewType: viewCol?.view_type,
              isHidden: !!viewCol?.is_hidden,
              view: viewCol.view?.views_id || {
                id: viewCol.views_id,
                view_type: viewCol?.view_type
              },
              sort: viewCol.sort || "",
              formRelationPageId: viewCol.form_relation_page_id,
              pinDirection: viewCol.pin_direction,
              additionalViewColumnProps: viewCol.additional_view_column_props,
              hasDivider: !!viewCol.has_divider,
              additionalConfig: view?.view?.additional_config,
              isRequired: !!viewCol.is_required,
              isGroupBy: !!viewCol.is_group_by,
              isGroupBySecond: !!viewCol.is_group_by_second,
              columnSections: mapApiViewAndViewColSectionsToSections(viewCol.columnSections),
              isFeaturedImage: !!viewCol.is_featured_image
            };
          }
          return acc;
        }, {}) || {},
      customPageViews:
        pageCol.column.ui_pages_views_columns?.reduce((acc: any, viewCol) => {
          if (viewCol?.view?.pages_id === pageId && !!viewCol?.view?.is_custom) {
            const view = pageViews?.find(
              (view) => view.is_custom && view.view.id === viewCol.views_id && view.pages_id === pageId
            );
            const viewColInput = {
              id: viewCol.id,
              viewType: viewCol?.view_type,
              isHidden: !!viewCol?.is_hidden,
              view: {
                id: viewCol.view?.views_id?.id,
                viewType: viewCol?.view_type,
                pageViewId: viewCol.view?.id
              },
              sort: viewCol.sort || "",
              formRelationPageId: viewCol.form_relation_page_id,
              pinDirection: viewCol.pin_direction,
              additionalViewColumnProps: viewCol.additional_view_column_props,
              hasDivider: !!viewCol.has_divider,
              additionalConfig: view?.view?.additional_config,
              isRequired: !!viewCol.is_required,
              isGroupBy: !!viewCol.is_group_by,
              isGroupBySecond: !!viewCol.is_group_by_second,
              columnSections: mapApiViewAndViewColSectionsToSections(viewCol.columnSections),
              isFeaturedImage: !!viewCol.is_featured_image
            };
            acc[viewCol.view?.views_id?.id || ""] = viewColInput;
          }
          return acc;
        }, {}) || {},
      accessRoleIds
    };
  });
  return convertedCols.sort((a, b) => {
    return (a.createdAt || 0) > (b.createdAt || 0) ? 1 : -1;
  }) as TableColumnType[];
};

export const mapApiViewColumnToTableViewColumn = (apiViewColumn: ApiPageViewColumn): TablePageViewColumn => {
  return {
    id: apiViewColumn.id,
    viewsId: apiViewColumn.views_id,
    columnsId: apiViewColumn.columns_id,
    formRelationPageId: apiViewColumn.form_relation_page_id,
    isHidden: !!apiViewColumn.is_hidden,
    isRequired: !!apiViewColumn.is_required,
    isFeaturedImage: !!apiViewColumn.is_featured_image,
    isGroupBy: !!apiViewColumn.is_group_by,
    isGroupBySecond: !!apiViewColumn.is_group_by_second,
    hasDivider: !!apiViewColumn.has_divider,
    additionalViewColumnProps: apiViewColumn.additional_view_column_props,
    workspaceId: apiViewColumn.workspace_id,
    sort: apiViewColumn.sort,
    columnSections: mapApiViewAndViewColSectionsToSections(apiViewColumn.columnSections),
    view: apiViewColumn?.view?.views_id || {
      id: apiViewColumn.view?.views_id?.id || "",
      view_type: apiViewColumn.view_type,
      isPageViewCustom: !!apiViewColumn?.view?.is_custom
    }
  };
};

export const mapApiPageViewsToPageViews = (apiViews: ApiPageViews[]): TableViewConfig[] => {
  return apiViews.map((apiView) => {
    const accessRoleIds = apiView.pageViewRoles?.map((role) => role.role_id || "") || [];
    return {
      id: apiView.id, // id from ui_pages_views
      viewType: apiView.view?.view_type,
      viewId: apiView.view?.id,
      isDeleted: !!apiView.view?.is_deleted,
      multiselectEnabled: !!apiView.view?.multiselect_enabled,
      disableExpand: !!apiView.view?.disable_expand,
      displayWithIcons: !!apiView.view?.display_with_icons,
      requiredColumns: apiView.columns?.filter((col) => !!col.is_required).map((col) => col.columns_id) || [],
      columns: apiView.columns?.map((col) => mapApiViewColumnToTableViewColumn(col)) || [],
      tabs: apiView.tabs?.length ? mapApiPageViewTabsToPageViewTabs(apiView.tabs) : [],
      columnColors: apiView.view?.column_colors,
      defaultPageTab: apiView.view?.default_page_tab,
      additionalConfig: apiView.view?.additional_config,
      subType: apiView.view?.sub_type,
      isDefault: !!apiView.view?.is_default,
      isCustom: !!apiView.view?.is_custom,
      isPageViewCustom: !!apiView.is_custom,
      title: apiView.view?.title,
      defaultSectionId: apiView.default_section_id,
      recordDefaultSectionId: apiView.record_default_section_id,
      workspaceId: apiView?.workspace_id,
      config: apiView?.config,
      defaultSection: apiView?.defaultSection
        ? mapApiColumnSectionsToSections([apiView.defaultSection])?.[0]
        : undefined,
      sections: apiView?.sections
        ? mapApiViewAndViewColSectionsToSections(apiView?.sections.map((section) => ({ id: section.id, section })))
        : undefined,
      conditionalFormatting: apiView.conditionalFormatting
        ? mapApiColumnRuleToPageColumnRule(apiView.conditionalFormatting)
        : undefined,
      accessRoleIds,
      isAdminView: !!accessRoleIds?.find((roleId) => `${roleId}` === "40")
    };
  });
};

export const mapApiPageViewTabsToPageViewTabs = (apiTabs: ApiPageViewTabs[]): TablePageViewTab[] => {
  return apiTabs.map((apiTab) => {
    return {
      id: apiTab.id,
      tabId: apiTab.tab?.id || apiTab.ui_tab_id,
      page:
        typeof apiTab.tab?.tab_page_id === "string" || typeof apiTab.tab?.tab_page_id === "number"
          ? { id: (apiTab.tab?.tab_page_id as string) || "", path: "", title: "", table_name: "" }
          : apiTab.tab?.tab_page_id,
      defaultView: apiTab.tab?.default_view,
      sidebarDefaultView: apiTab.tab?.sidebar_default_view,
      listDetailDefaultView: apiTab.tab?.list_detail_default_view,
      tableName: apiTab.tab?.tab_page_id?.table_name || "",
      filterRelations: apiTab.tab?.filter_relations,
      title: apiTab.tab?.title || "",
      icon: apiTab.tab?.icon,
      hasAdd: !!apiTab.tab?.add_page_id,
      addPage:
        typeof apiTab.tab?.add_page_id === "string" || typeof apiTab.tab?.add_page_id === "number"
          ? { id: (apiTab.tab?.add_page_id as string) || "", path: "", title: "", table_name: "" }
          : apiTab.tab?.add_page_id,
      addPageFilters: apiTab.tab?.addPageFilters ? mapApiColRuleToFilters(apiTab.tab.addPageFilters) : undefined,
      addPageFilterId: apiTab.tab?.addPageFilters?.id,
      addTableName: apiTab.tab?.add_page_id?.table_name,
      recordIdSource: apiTab.tab?.recordid_source,
      sort: apiTab.tab?.sort,
      visibility: apiTab.tab?.visibility,
      uiMenuItemId: apiTab.tab?.ui_menu_item_id,
      createdAt: apiTab.created_at,
      defaultAddExpanded: !!apiTab.tab?.default_add_expanded,
      showRecordCount: !!apiTab.tab?.show_record_count,
      isHidden: !!apiTab.is_hidden,
      isChild: !!apiTab.is_child,
      additionalConfig: apiTab.additional_config,
      tabFilterId: apiTab.tab?.tabFilters?.id,
      tabFilters: apiTab.tab?.tabFilters ? mapApiColRuleToFilters(apiTab.tab.tabFilters) : undefined
    };
  });
};

export const mapApiPageToPage = (apiPage: ApiPage): Page => {
  if (!apiPage) return {} as Page;
  const accessRoleIds = apiPage?.pageRoles?.map((role) => role.role_id || "") || [];
  return {
    ...apiPage,
    isDefaultTablePage: !!apiPage.is_default_table_page,
    ui_pages_actions: mapApiPageActionsToPageActions(apiPage.ui_pages_actions || []),
    columns: apiPage.columns?.length
      ? mapPageColumnsToType(apiPage.columns || [], apiPage.views || [], apiPage.id)
      : [],
    views: apiPage.views?.length ? mapApiPageViewsToPageViews(apiPage.views || []) : [],
    pageFilters: apiPage?.pageFilters?.columnRules?.length
      ? { id: apiPage.pageFilters?.id, filters: mapApiColRuleToFilters(apiPage.pageFilters) }
      : undefined,
    conditionalFormatting: apiPage.conditionalFormatting?.length
      ? mapApiColumnRuleToPageColumnRule(apiPage.conditionalFormatting)
      : undefined,
    algoliaSearchTableId: apiPage.algolia_search_table_id,
    accessRoleIds
  } as Page;
};

export const mapApiViewToTableViewConfig = (apiView: ApiView, isCustomView?: boolean): TableViewConfig => {
  if (!apiView) return {} as TableViewConfig;
  const allColumns = apiView.columns?.map((viewCol) => {
    return {
      column: viewCol.column,
      ...viewCol.pageColumn,
      viewColumnId: viewCol.id,
      viewColumnSortOrder: viewCol.sort
        ? {
            [apiView.view_type]: viewCol.sort
          }
        : undefined,
      lookupFilters: apiView.additional_config?.isStaticCustomForm
        ? viewCol.lookupFilters
        : viewCol.pageColumn?.lookupFilters,
      columnFilters: apiView.additional_config?.isStaticCustomForm
        ? viewCol.columnFilters
        : viewCol.pageColumn?.columnFilters
    };
  }) as ApiPageColumn[];
  const accessRoleIds = isCustomView ? apiView?.viewRoles?.map((role) => role.role_id || "") : undefined;
  return {
    ...apiView,
    viewType: apiView.view_type,
    additionalConfig: apiView.additional_config,
    viewColumns: allColumns?.length ? mapPageColumnsToType(allColumns, [], "", isCustomView) : [],
    accessRoleIds,
    sections: apiView?.sections
      ? mapApiViewAndViewColSectionsToSections(apiView?.sections.map((section) => ({ id: section.id, section })))
      : undefined
  } as TableViewConfig;
};

export const mapApiPageActionsToPageActions = (actions: ApiPageAction[]): PageAction[] => {
  return actions.map((action) => {
    const accessRoleIds = action.actionRoles?.map((role) => role.role_id || "");
    return {
      ...action,
      viewId: action.ui_views_id,
      view: action.view ? mapApiViewToTableViewConfig(action.view) : action.view,
      conditionalViewRules: action.conditionalViewRules
        ? mapApiColumnRuleToPageColumnRule([action.conditionalViewRules])?.[0]
        : undefined,
      accessRoleIds,
      isAdminAction: !!accessRoleIds?.find((roleId) => `${roleId}` === "40")
    };
  });
};

export const mapApiAlgoliaSearchTableToAlgoliaSearchTable = (apiSearchTable: ApiAlgoliaSearchTable) => {
  if (!apiSearchTable) return {} as AlgoliaSearchTable;
  return {
    id: apiSearchTable.id,
    title: apiSearchTable.title,
    isDeleted: apiSearchTable.is_deleted,
    createdAt: apiSearchTable.created_at,
    updatedAt: apiSearchTable.updated_at,
    createdBy: apiSearchTable.created_by,
    updatedBy: apiSearchTable.updated_by,
    indexName: apiSearchTable.index_name,
    enableGlobalSearch: apiSearchTable.enable_global_search,
    tableName: apiSearchTable.table_name,
    resultConfig: apiSearchTable.result_config,
    hasCreateAction: apiSearchTable.has_create_action,
    sortOrder: apiSearchTable.sort_order,
    enableMasterIndex: apiSearchTable.enable_master_index,
    masterIndexConfig: apiSearchTable.master_index_config,
    isReservedIndex: apiSearchTable.is_reserved_index,
    additionalConfig: apiSearchTable.additional_config,
    syncError: apiSearchTable.sync_error,
    linkedPage: apiSearchTable.linkedPage,
    workspaceId: apiSearchTable.workspace_id,
    columns:
      apiSearchTable.algoliaColumns?.map((algCol) => {
        const pageCol = algCol.pageColumn;
        return {
          id: pageCol.column.id,
          name: pageCol.column.name,
          header: pageCol.column.header,
          createdAt: pageCol.column.created_at,
          type: pageCol.column.type,
          pageColumnId: pageCol.id,
          pageId: pageCol.page_id?.id || pageCol.page_id,
          hasFilter: !!pageCol.column.has_filter,
          filterSortOrder: pageCol.column.filter_sort_order,
          hasBulkEdit: !!pageCol.column.has_bulk_edit,
          cellConfig: pageCol.column.cell_config,
          isStatic: !!pageCol.column.is_static,
          isLookup: !!pageCol.column.is_lookup,
          isFormula: !!pageCol.column.is_formula,
          lookupPath: pageCol.column.lookup_path,
          isMultiple: !!pageCol.column.is_multiple,
          isEditable: !!pageCol.column.is_editable,
          isSelect: !!pageCol.column.is_select,
          dbType: pageCol.column.db_type,
          defaultValues: pageCol.column.default_values,
          formula: pageCol.column.formula,
          isRollup: !!pageCol.column.rollup_config?.sourceColId,
          writeRollupConfig: pageCol.column.write_rollup_config,
          lookupFilters: pageCol.lookupFilters ? mapApiColRuleToFilters(pageCol.lookupFilters) : undefined,
          lookupFiltersId: pageCol.lookupFilters?.id
        };
      }) || []
  };
};

export const mapApiMenuActionsToMenuActions = (apiMenuActions: ApiMenuAction[]): MenuAction[] => {
  return apiMenuActions?.map((apiMenuAction) => {
    const accessRoleIds = apiMenuAction.menuActionRoles?.map((role) => role.role_id || "") || [];
    return {
      ...apiMenuAction,
      accessRoleIds
    };
  });
};

export const mapApiMenuItemToMenuItem = (apiMenuItem: ApiMenuItem) => {
  const accessRoleIds = apiMenuItem.menuItemRoles?.map((role) => role.role_id) || [];
  return {
    ...apiMenuItem,
    ui_menu_actions: mapApiMenuActionsToMenuActions(apiMenuItem.ui_menu_actions || []),
    additionalConfig: apiMenuItem.additional_config,
    accessRoleIds,
    showAdminOnly: !!accessRoleIds?.find((roleId) => `${roleId}` === "40"),
    pageViewSectionId: apiMenuItem.page_view_section_id,
    viewId: apiMenuItem.ui_views_id,
    defaultSectionConfig: apiMenuItem.pageViewSection?.page_view_id?.default_section_id?.config
  } as MenuItem;
};

export const getPageFiltersFromTab = ({
  recordPage,
  currentTabVal,
  recordData,
  isParentPublicId,
  recordId,
  currentDealId,
  tabPageData,
  currentRecordId,
  isRecordPageTab,
  extendedSchema,
  currentUser,
  currentRecordTableName
}: {
  recordPage: Page;
  currentTabVal?: TablePageViewTab;
  recordData?: RecordItem;
  isParentPublicId?: boolean;
  isRecordPageTab?: boolean;
  recordId?: string;
  currentDealId?: string;
  tabPageData?: Page;
  currentRecordId?: string;
  extendedSchema?: ExtendedSchema;
  currentUser?: User;
  currentRecordTableName?: string;
}) => {
  let finalFilters: any = {};
  let nestedTableFilter = "";
  let finalFiltersList = [];

  const isTabRecordTable = currentTabVal?.tableName === recordPage.table_name;
  // ##HARDCODED filtering of tabs by notes_links table
  const isNoteLinkTable = currentTabVal?.tableName === "notes" && recordPage?.table_name;
  const noteLinkFK =
    isNoteLinkTable && extendedSchema ? getNotesLinkForeignKey(recordPage.table_name, extendedSchema) : null;
  if (isNoteLinkTable) {
    if (noteLinkFK) {
      // Notes_links has a foreign key to the record table we filter by that
      finalFilters = {
        id: `${NOTES_LINKS_TABLE}_filter`,
        filterField: noteLinkFK.attributeId,
        filterValue: recordId,
        filterOperator: "equals",
        useFilterLookupColumn: true,
        filterLookupPath: {
          "0": {
            lookupTableName: NOTES_LINKS_TABLE,
            lookupType: LookupTypes.JOIN,
            lookupForeignKey: "",
            lookupColumnLabel: `${NOTES_LINKS_TABLE}_filter`,
            lookupColumns: [noteLinkFK.attributeId]
          }
        }
      };

      finalFiltersList = [finalFilters];
    }
  }
  if (currentTabVal?.filterRelations && !noteLinkFK?.attributeId) {
    let finalFilterValue = isParentPublicId ? recordData?.id : recordId;
    if (currentTabVal?.recordIdSource?.columnId && recordData) {
      finalFilterValue = getPropertyFromRecordSource({
        columns: recordPage?.columns || [],
        record: recordData,
        recordIdSource: currentTabVal?.recordIdSource
      });
    }
    const filterRelationKeys = Object.keys(currentTabVal.filterRelations);
    filterRelationKeys.forEach((key: string) => {
      const filterField = currentTabVal.filterRelations?.[key];
      const islookupForeignKey = filterField?.lookupType === LookupTypes.FOREIGN;
      let finalFilterField = filterField?.lookupColumn;
      // In some filter relation, notes list id is selected from the parent and stores as a foreign key
      if (islookupForeignKey && !(tabPageData?.table_name === "notes" && finalFilterField === "notes_list_id")) {
        finalFilterField = filterField?.lookupForeignKey || filterField?.lookupColumn || "";
      } else if (filterField?.lookupTableName) {
        nestedTableFilter += isTabRecordTable
          ? filterField.lookupColumn
          : filterField.lookupTableName + "." + filterField.lookupColumn;
      }
      if (filterField?.lookupColumn) {
        if (
          filterRelationKeys?.length === 1 &&
          !islookupForeignKey &&
          !isTabRecordTable &&
          filterField.lookupTableName
        ) {
          finalFilterField = filterField.lookupTableName + "." + filterField.lookupColumn;
        }
        finalFilters = {
          id: `${currentTabVal.tabId}_filter_${key}`,
          filterField: finalFilterField,
          filterValue: finalFilterValue,
          filterOperator: "equals",
          filterLookupPath: filterField?.lookupTableName
            ? {
                "0": {
                  lookupTableName: filterField.lookupTableName || "",
                  lookupType: filterField.lookupType || LookupTypes.JOIN,
                  lookupForeignKey: filterField.lookupForeignKey || "",
                  lookupColumnLabel: filterField.lookupColumn || "",
                  lookupColumns: [filterField.lookupColumn || ""]
                }
              }
            : undefined
        };
      } else {
        nestedTableFilter += nestedTableFilter?.length
          ? `(${filterField?.lookupTableName || ""}`
          : filterField?.lookupTableName || "";
      }
    });

    if (finalFilters?.filterField) {
      finalFiltersList = [finalFilters];
      const orFilterFields: string[] = [];
      // Check if tab page has filters
      if (tabPageData?.pageFilters?.filters?.length) {
        // Check for special defaults in filters
        const finalTabFilters = tabPageData?.pageFilters?.filters?.map((filter) => {
          if (filter.filterOperator === FILTER_OPERATOR.CURRENT_DEAL && currentDealId) {
            return {
              ...filter,
              filterValue: currentDealId
            };
          }
          if (
            filter.filterOperator === FILTER_OPERATOR.CURRENT_RECORD &&
            (currentRecordId || (isRecordPageTab && recordId))
          ) {
            return {
              ...filter,
              filterValue: currentRecordId || recordId
            };
          }
          if (filter.filterOperator === FILTER_OPERATOR.OR && filter.filterGroup?.length) {
            return {
              ...filter,
              filterGroup: filter.filterGroup.map((groupFilter) => {
                orFilterFields.push(groupFilter.filterField || "");
                if (groupFilter.filterOperator === FILTER_OPERATOR.CURRENT_DEAL && currentDealId) {
                  return {
                    ...groupFilter,
                    filterValue: currentDealId
                  };
                }
                if (groupFilter.filterOperator === FILTER_OPERATOR.CURRENT_RECORD && currentRecordId) {
                  return {
                    ...groupFilter,
                    filterValue: currentRecordId
                  };
                }
                return groupFilter;
              })
            };
          }
          return filter;
        });

        finalFiltersList = [...finalTabFilters, finalFilters];
        // Remove AND filter for a column if it is present in the OR filter group
        if (orFilterFields?.length) {
          finalFiltersList = finalFiltersList.filter((filter) => {
            if (orFilterFields.includes(filter.filterField) && filter.filterOperator !== FILTER_OPERATOR.OR) {
              return null;
            }
            return true;
          });
        }
      }
    }
  }

  if (currentTabVal?.tabFilters?.length) {
    const updatedTabFilters = currentTabVal.tabFilters
      ?.map((tabFilter: TableFilterType) => {
        if (tabFilter.filterOperator === FILTER_OPERATOR.CURRENT_DEAL && currentDealId) {
          return {
            ...tabFilter,
            filterValue: currentDealId
          };
        }
        if (
          tabFilter.filterOperator === FILTER_OPERATOR.CURRENT_RECORD &&
          (currentRecordId || (isRecordPageTab && recordId))
        ) {
          if (currentRecordTableName === recordPage?.table_name) {
            return {
              ...tabFilter,
              filterValue: currentRecordId || recordId,
              excludeFromInner: false
            };
          } else {
            return undefined;
          }
        }
        if (tabFilter.filterOperator === FILTER_OPERATOR.CURRENT_USER) {
          // ##HARDCODED check for created_by or updated_by
          if (tabFilter.filterField && !["created_by", "updated_by"].includes(tabFilter.filterField)) {
            return {
              ...tabFilter,
              filterValue: currentUser?.id
            };
          } else {
            return {
              ...tabFilter,
              filterValue: currentUser?.user_id
            };
          }
        }
        return tabFilter;
      })
      .filter((filter) => !!filter);
    finalFiltersList = [...finalFiltersList, ...updatedTabFilters];
  }

  return {
    finalFiltersList
  };
};

export const mapApiUserHistoryFieldsToUserHistory = (userHistory: ApiUserHistory): UserHistory => {
  return {
    id: userHistory.id,
    peopleId: userHistory.people_id,
    pageTitle: userHistory.page_title,
    pagePath: userHistory.page_path,
    additionalFields: userHistory.additional_fields
  };
};

export const mapApiUserPreferencesToUserPreferences = (userPreference: ApiUserPreference): UserPreference => {
  return {
    id: userPreference.id,
    userId: userPreference.contact_user_id,
    additionalInfo: userPreference.additional_info,
    createdAt: userPreference.created_at,
    updatedAt: userPreference.updated_at,
    sortOrder: userPreference.sort_order
  };
};

export const mapApiContactPhoneNumberToContactPhoneNumber = (
  apiContactPhoneNumber: ApiContactPhoneNumber
): ContactPhoneNumber => {
  return {
    id: apiContactPhoneNumber.id,
    contactId: apiContactPhoneNumber.contact_id,
    phone: apiContactPhoneNumber.phone,
    formattedPhone: formatPhoneNumber(apiContactPhoneNumber.phone),
    phoneType: apiContactPhoneNumber.phone_type,
    isPrimaryNumber: apiContactPhoneNumber.is_primary_number,
    isNotInService: apiContactPhoneNumber.is_not_in_service,
    isWrongNumber: apiContactPhoneNumber.is_wrong_number,
    isValid: apiContactPhoneNumber.is_valid,
    isDnc: apiContactPhoneNumber.is_dnc,
    dealId: apiContactPhoneNumber.deal_id,
    twilioCalls: apiContactPhoneNumber.twilio_calls,
    twilioAnswerHumanCount: apiContactPhoneNumber.twilio_answer_human_count,
    twilioVoicemailsLeft: apiContactPhoneNumber.twilio_voicemails_left
  };
};
